import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { Divider, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { getBackground, getThemeContraste } from '../../utils/theme';
import { getPerm, getUser, permRender } from '../../utils/user';
import { Add, ArrowLeft, BookMark, Done, GpsFixed, IconMenu, People, TreeBox, ListIcon, ListFolder } from '../icons';
import { ButtonLinkIcon, ButtonPesquisar } from '../button';
import { verificarRota } from '../../utils/geral';
import { GeralContext } from '../../context';
import { MenuAppBar } from '../menu';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export function NavBar() {
    const [state, setState] = React.useState(false);
    const { toggleDesativarPesquisa, toggleAtualizar } = React.useContext(GeralContext);

    function ListMenuItem(props) {
        return (
            <Link to={props.to} style={{ textDecoration: 'none', width: '100%', color: getThemeContraste() }} onClick={() => setState(false)}>
                <ListItem disablePadding onClick={toggleDesativarPesquisa}>
                    <ListItemButton>
                        <ListItemIcon>
                            {props.icon}
                        </ListItemIcon>
                        <ListItemText primary={props.caption} />
                    </ListItemButton>
                </ListItem>
            </Link>
        );
    }

    return (
        <Paper elevation={2}>
            <AppBar position="static" style={{ background: getBackground() }}>
                <Container maxWidth="xxl">
                    <Toolbar disableGutters >
                        <IconButton
                            aria-label="open drawer"
                            onClick={() => setState(true)}
                            edge="start"
                            style={{ marginRight: '10px' }}
                        >
                            <IconMenu style={{ background: getThemeContraste() }} />
                        </IconButton>
                        <Link to={'/sac'} onClick={() => toggleAtualizar(true)}>
                            <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" />
                        </Link>
                        <Box style={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }} />
                        {getPerm('CR') && !verificarRota('externo') ?
                            <Box onClick={() => toggleDesativarPesquisa}>
                                <ButtonLinkIcon to='/sac/create' title='Novo Atendimento' icon={<Add style={{ background: getThemeContraste() }} fontSize='25px' />} onClick={toggleDesativarPesquisa} />
                            </Box> : ''}
                        {(!verificarRota('sac') && !verificarRota('testcomplete')) || verificarRota('create') ? '' : <ButtonPesquisar />}
                        <MenuAppBar />
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor={'left'}
                open={state}
                onClose={() => setState(false)}
            >
                <DrawerHeader style={{ background: 'inherit' }}>
                    <Toolbar style={{ textAlign: 'center' }}>
                        <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" />
                        <Box sx={{ mr: 3 }} />
                        <IconButton onClick={() => setState(false)} style={{ color: getThemeContraste() }}>
                            <ArrowLeft fontSize='25px' />
                        </IconButton>
                    </Toolbar>
                </DrawerHeader>
                <List style={{ width: '250px' }}>
                    <ListMenuItem to={'/sac'} icon={<ListIcon />} caption='Atendimentos' />
                    <ListMenuItem to={'/finalizados'} icon={<Done />} caption='Finalizados' />
                    <Divider />
                    <ListMenuItem to={'/clientes'} icon={<People />} caption='Clientes' />
                    <ListMenuItem to={'/marcadores'} icon={<BookMark />} caption='Marcadores' />
                    <ListMenuItem to={'/movimentos'} icon={<SwipeVerticalIcon />} caption='Movimentos' />
                    <ListMenuItem to={'/rastreios'} icon={<GpsFixed />} caption='Rastreios' />
                    <ListMenuItem to={'/setores'} icon={<TreeBox />} caption='Setores' />
                    {/* <ListMenuItem to={'/faq'} icon={<TreeBox />} caption='FAQ' /> */}
                    {permRender('ER', <ListMenuItem to={'/versao'} icon={<ListFolder />} caption='Versionamento' />)}
                    {/* {getUser().nome === 'JHENNIFER' || getUser().nome === 'FILIPE' ? <ListMenuItem to={'/testcomplete'} icon={<ViewKanbanIcon />} caption='TestComplete' /> : ''} */}
                    {/* <ListMenuItem to={'/teste'} icon={<Lab />} caption='TESTE' /> */}
                </List>
            </Drawer>
        </Paper>
    );
}

export function NavBarSimple() {
    return (
        <Paper elevation={2}>
            <AppBar position="static" style={{ background: getBackground() }}>
                <Container maxWidth="xxl">
                    <Toolbar disableGutters >
                        <Avatar alt="Logo Redsis" src="../../../app-assets/img/logo/logo.png" />
                    </Toolbar>
                </Container>
            </AppBar>
        </Paper>
    );
}