import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AZIcon, IconMenu, IconMessage, ListIcon, ModeMail, MoonIcon, MoreV, Power, Settings, SunIcon, IconMark } from '../icons';
import { Checkbox, IconButton } from '@mui/material';
import { verificarRota } from '../../utils/geral';
import { getLayout, getTheme, getThemeContraste, setLayout, setTheme } from '../../utils/theme';
import { Link } from 'react-router-dom';
import { StackLeft } from '../stack';
import { Title } from '../typography';
import { getUser, permRender } from '../../utils/user';
import useConfig from '../../hooks/useConfig';
import { GeralContext } from '../../context';

export function MenuAppBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (verificarRota('externo') ? '' :
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreV color={getThemeContraste()} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <SubMenuConfig />
                <MenuItem >
                    <Link to={'/login'} style={{ textDecoration: 'none', width: '100%', color: getThemeContraste() }} onClick={() => sessionStorage.clear()}>
                        <StackLeft>
                            <Power style={{ background: getThemeContraste() }} /><Title title='Sair' />
                        </StackLeft>
                    </Link>
                </MenuItem>
            </Menu >
        </div >
    );
}


function SubMenuConfig() {
    const { getIgFilas, igFila, setIgFilas } = useConfig();
    const [fila, setFila] = React.useState(igFila);
    const { toggleDesativarPesquisa, toggleOrdemSac, ordemSac } = React.useContext(GeralContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeFila = () => {
        setFila(!fila);
        setIgFilas(!fila);
    }

    React.useEffect(() => {
        getIgFilas();
        // eslint-disable-next-line
    }, [])


    return <div>
        <MenuItem
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{ color: getThemeContraste() }}
        >
            <StackLeft >
                <Settings /><Title title='Config.' />
            </StackLeft>
        </MenuItem>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            style={{ marginLeft: '-10px' }}
        >
            {
                getTheme() === 'dark' ?
                    <MenuItem style={{ color: getThemeContraste() }} onClick={() => setTheme('light')}>
                        <StackLeft>
                            <SunIcon color={getThemeContraste()} /><Title title='Tema Light' />
                        </StackLeft>
                    </MenuItem> :
                    <MenuItem style={{ color: getThemeContraste() }} onClick={() => setTheme('dark')}>
                        <StackLeft>
                            <MoonIcon color={getThemeContraste()} /><Title title='Tema Dark' />
                        </StackLeft>
                    </MenuItem>
            }
            {
                getLayout() === 'email' ?
                    <MenuItem style={{ color: getThemeContraste() }} onClick={() => setLayout('padrao')}>
                        <StackLeft>
                            <ListIcon color={getThemeContraste()} /><Title title='Modo Padrão' />
                        </StackLeft>
                    </MenuItem>
                    :
                    <MenuItem style={{ color: getThemeContraste() }} onClick={() => setLayout('email')}>
                        <StackLeft>
                            <ModeMail color={getThemeContraste()} /><Title title='Modo Email' />
                        </StackLeft>
                    </MenuItem>
            }

            {
                ordemSac === 'padrao' ?
                    <MenuItem style={{ color: getThemeContraste() }} onClick={handleClose}>
                        <StackLeft onClick={() => toggleOrdemSac('az')}>
                            <AZIcon style={{ background: getThemeContraste() }} /><Title title='Ordem Alfabética' />
                        </StackLeft>
                    </MenuItem>
                    :
                    <MenuItem style={{ color: getThemeContraste() }} onClick={handleClose}>
                        <StackLeft onClick={() => toggleOrdemSac('padrao')}>
                            <IconMenu style={{ background: getThemeContraste() }} /><Title title='Ordem Padrão' />
                        </StackLeft>
                    </MenuItem>
            }

            {getUser().nome === 'JHENNIFER' || getUser().nome === 'FILIPE' ?
                <MenuItem style={{ color: getThemeContraste() }} onClick={handleClose}>
                    <Link to={'/mensagem'} style={{ textDecoration: 'none', width: '100%', color: getThemeContraste() }} onClick={toggleDesativarPesquisa}>
                        <StackLeft>
                            <IconMessage style={{ background: getThemeContraste() }} /><Title title='Cad. Mensagem' />
                        </StackLeft>
                    </Link>
                </MenuItem>
                : ''}

            {getUser().nome === 'JHENNIFER' || getUser().nome === 'FILIPE' ?
                <MenuItem style={{ color: getThemeContraste() }} onClick={handleClose}>
                    <Link to={'/mudartags'} style={{ textDecoration: 'none', width: '100%', color: getThemeContraste() }} onClick={toggleDesativarPesquisa}>
                        <StackLeft>
                            <IconMark style={{ background: getThemeContraste() }} /><Title title='Mudar Tags' />
                        </StackLeft>
                    </Link>
                </MenuItem>
                : ''}

            {permRender('CR',
                <MenuItem style={{ color: getThemeContraste(), marginLeft: '-8px' }}>
                    <Checkbox checked={fila}
                        onChange={handleChangeFila}
                    />
                    <Title title='Ignorar Fila' />
                </MenuItem>
            )}
        </Menu >
    </div >
}