import { getLocal } from "../storage";

export function formatCnpjCpf(valor) {
  if (valor !== undefined) {
    if (valor.length === 11) {
      return valor.slice(0, 3, valor) + '.' + valor.slice(3, 6, valor) + '.' + valor.slice(6, 9, valor) + '/' + valor.slice(9, 11, valor);
    } else {
      return valor.slice(0, 2, valor) + '.' + valor.slice(2, 5, valor) + '.' + valor.slice(5, 8, valor) + '/' + valor.slice(8, 12, valor) + '-' + valor.slice(12, 14, valor);
    }
  } else {
    return '';
  }
}

export function coalesceString(texto, valor) {
  if (texto === '') {
    return valor;
  } else {
    return texto;
  }
}

export function listaLength(lista) {
  try {
    return lista.length;
  } catch {
    return 0;
  }
}

export function getMensagemFinal() {
  return JSON.parse(getLocal('msgFinalizar'));
}