import { Box, Grid, Paper } from "@mui/material";
import { getLayout, heightDefault } from "../../utils/theme";
import { getSetor } from "../../utils/sac";

function LayoutEmail(props) {
    return (
        <Grid container pb={1}>
            <Grid item xs={12} md={5}
                sx={{
                    height: heightDefault() - 50,
                    overflow: 'auto',
                    background: 'inherit'
                }}
            >
                {props.colL}
            </Grid>
            <Grid item xs={12} md={7}
                sx={{
                    height: heightDefault() - 50,
                    overflow: 'auto',
                    background: 'inherit'
                }}
            >
                <Paper style={{ background: 'inherit', margin: '10px', minHeight: '90vh' }} elevation={0}>
                    {props.colR}
                </Paper>
            </Grid>
        </Grid >
    );
}
function LayoutPadrao(props) {
    return (
        <Box sx={{
            height: heightDefault() - 150,
            overflow: 'auto',
            paddingBottom: '100px'
        }} >
            {props.children}
        </Box>
    );
}

export default function LayoutSac(props) {
    return (
        getLayout() === 'padrao' || getSetor() === 'TC' ?
            <LayoutPadrao>
                {props.padrao}
            </LayoutPadrao>
            :
            <LayoutEmail
                colL={props.colL}
                colR={props.colR}
            />

    )
}



