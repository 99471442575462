import { useState, useContext } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";

const useRastreios = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState(true);
  const [rastreios, setRastreios] = useState([]);
  const { toggleMensagem, toggleAtualizar } = useContext(GeralContext);

  var config = {
    headers: {
      'Authorization': token
    },
  };

  const getRastreios = async () => {
    setLoading(true);
    const response = await axios.get('/atendimentos/rastreios', config)
    setRastreios(response.data.result);
    setLoading(false)
  }

  const postRastreio = async (parameters) => {
    // setLoading(true);
    let data = {
      "descricao": parameters.descricao,
      "master": parameters.master
    }
    const response = await axios({
      method: 'post',
      url: '/rastreios',
      headers: {
        authorization: token
      },
      data: data,
    });
    toggleMensagem(response.data.result, 'success');
    // setLoading(false)
  }

  const putRastreio = async (parameters) => {
    // setLoading(true);
    let data = {
      "descricao": parameters.descricao,
      "master": parameters.master
    }
    axios({
      method: 'put',
      url: `/atendimentos/rastreios/${parameters.codigo}`,
      headers: {
        authorization: token
      },
      data: data,
    }).then(response => {
      toggleMensagem(response.data.result, 'success');
      // setLoading(false)
    })
      .catch(error => {
        toggleMensagem(error.message, 'error');
        // setLoading(false)
      });
  }

  const removeRastreio = async (id) => {
    setLoading(true);
    const response = await axios({
      method: 'delete',
      url: `/atendimentos/rastreios/${id}`,
      headers: {
        authorization: token
      }
    }).then(response => {
      toggleMensagem(response.data.result, 'success');
      // setLoading(false)
    })
      .catch(error => {
        toggleMensagem(error.message, 'error');
        // setLoading(false)
      });
  }

  const addRemoveRastreio = async (id, rastreio, method) => {
    // setLoading(true)
    let data = { 'rastreio': rastreio };
    if (method === 'post') {
      const response = await axios.put(`/atendimentos/${id}/rastreio`, data, config);
      toggleMensagem(response.data.result, 'success');
    } else {
      const response = await axios.delete(`/atendimentos/${id}/rastreio`, config);
      toggleMensagem(response.data.result, 'success');
    }
    toggleAtualizar(true)
    // setLoading(false);
  }

  return { postRastreio, getRastreios, putRastreio, removeRastreio, addRemoveRastreio, rastreios, loading }
}
export default useRastreios;