import * as React from 'react';
import { Box, Button } from '@mui/material';
import { setLocal } from '../../utils/storage';
import { ContainerPages } from '../../components/container';
import { TextFieldDefault } from '../../components/textfield';
import { StackJustify, StackRight } from '../../components/stack';
import { iniMensagemFinal } from '../../utils/constructor';
import { getMensagemFinal } from '../../utils/string';
import { SelectTags } from '../../components/select';
import { GeralContext } from '../../context';
import { Check, Delete } from '../../components/icons';

export default function CadMensagem() {
    const [mensagem, setMensagem] = React.useState(iniMensagemFinal);
    const { toggleMensagem } = React.useContext(GeralContext);

    const handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setMensagem((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    React.useEffect(() => {
        const msgFinal = getMensagemFinal();
        if (msgFinal) {
            setMensagem({
                "solucao": msgFinal.solucao,
                "versao": msgFinal.versao,
                "tag": msgFinal.tag
            })
        }
    }, [])

    const limpar = () => {
        setLocal('msgFinalizar', JSON.stringify(iniMensagemFinal));
        setMensagem(iniMensagemFinal);
        toggleMensagem('Mensagem excluida com sucesso!', 'success');
    }

    const salvar = () => {
        setLocal('msgFinalizar', JSON.stringify(mensagem));
        toggleMensagem('Mensagem gravada com sucesso!', 'success');
    }


    return (
        <ContainerPages activeAuxBar={true} title='Mensagem'>
            <Box p={1}>
                <SelectTags onChange={handleChange} value={mensagem.tag} />
                <TextFieldDefault id='solucao' name='solucao' autoFocus={true} onChange={handleChange} value={mensagem.solucao} label='Solução' multiline rows={5} />
                <TextFieldDefault id='versao' name='versao' onChange={handleChange} value={mensagem.versao} label='Versão' />
                <StackRight>
                    <Button variant='contained' size='small' color='red' onClick={limpar}><StackJustify><Delete /> Limpar</StackJustify></Button>
                    <Button variant='contained' size='small' color='green' onClick={salvar}><Check /><StackJustify>Salvar</StackJustify></Button>
                </StackRight>
            </Box>
        </ContainerPages>
    );
}

