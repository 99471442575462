import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useTipos from '../../hooks/useTipos';
import { agruparTags, colorDetail, colorSac, getListSetores, getSetor } from '../../utils/sac';
import { AccordionAtendimentos, AccordionGrupos } from './../../components/accordion';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import { getLayout, getTheme, getThemeContraste, heightScreen } from '../../utils/theme';
import { listaLength } from '../../utils/string';
import { GeralContext, SacContext } from '../../context';
import { HeaderSac } from '../../components/sac/header';
import { BodySac } from '../../components/sac/body';
import LayoutSac from '../layout';
import { orderList } from '../../utils/geral';
import useMovimento from '../../hooks/useMovimentos';
import { SacDialog } from '../dialog';
import { BoxAtendimentoTC, BoxGruposTC, BoxSubGruposTC } from '../box';

export function TabSetor() {
    const [value, setValue] = React.useState(getSetor());
    const { getNome } = useTipos();
    const { listSac, toggleSetor, sac, codigo, open, toggleOpen, toggleCodigo } = React.useContext(SacContext);
    const { pesquisar, togglePesquisarVinc, ordemSac, toggleAtualizar } = React.useContext(GeralContext);
    const { getMovimentos, listMovimento, putMovimentoAt } = useMovimento();
    const lista = listSac;
    let qtde = 0;

    React.useEffect(() => {
        getMovimentos(undefined, true);
    }, [])


    function drag(ev, sourceColumnId) {
        ev.dataTransfer.setData("text", ev.target.id);
        ev.dataTransfer.setData("sourceColumnId", sourceColumnId);
    }

    const allowDrop = (ev) => {
        ev.preventDefault();
    }

    const drop = async (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        var idSac = data;
        var destino = ev.target.id;
        if (destino !== '' || destino !== null || destino !== 'null')
            await putMovimentoAt(idSac, destino);
        toggleAtualizar(true)
    }

    function showSac() {
        toggleOpen(true);
    }

    const handleClose = () => {
        toggleOpen(false);
    };

    function ListarColunas(grupo, g) {
        return <BoxGruposTC key={g} id={grupo.codigo} grupo={grupo.grupo} registros={grupo.total} onDrop={(e) => drop(e, grupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(grupo.grupos) > 0 ? grupo.grupos.map(ListSubColunas) : listaLength(grupo.atendimentos) > 0 ? grupo.atendimentos.map(ListarItens) : ''}
        </BoxGruposTC>
    }

    function ListSubColunas(subGrupo, s) {
        return <BoxSubGruposTC key={s} id={subGrupo.grupo} grupo={subGrupo.grupo} registros={subGrupo.total} onDrop={(e) => drop(e, subGrupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(subGrupo.atendimentos) > 0 ? subGrupo.atendimentos.map(ListarItens) : ''}
        </BoxSubGruposTC>
    }

    function ListarItens(atendimento) {
        return <BoxAtendimentoTC key={atendimento.codigo} atendimento={atendimento} onDragStart={(e) => drag(e, atendimento.tags)} onDoubleClick={() => showSac()} onClick={() => toggleCodigo(atendimento.codigo)} />;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        qtde = 0;
    };

    function selectSetor(value) {
        setValue(value);
        toggleSetor(value);
        qtde = 0;
    }

    function Setores(setor, s) {
        let nome = '';
        let tipoSetor = ''
        qtde = 0;
        if (pesquisar.valor === '') {
            qtde = lista[0].atendimentos
            nome = getNome(setor) + (setor === getSetor() ? ` -  (${qtde}) ` : '');
            tipoSetor = setor;
        } else {
            qtde = setor.atendimentos;
            nome = getNome(setor.tipo) + (` -  (${qtde}) `);
            tipoSetor = setor.tipo;
        }
        return <Tab key={s} value={tipoSetor} label={nome} style={{ textTransform: 'initial' }}
            id={`simple-tab-${s}`} aria-controls={`simple-tabpanel-${s}`} onClick={() => selectSetor(tipoSetor)}
        />
    }

    function TabSetores(tabsetor) {
        return <TabPanel key={tabsetor.tipo} value={value} index={tabsetor.tipo} sx={{ padding: '0 !important' }}>
            {tabsetor.tipo === value ? tabsetor.grupos.map(Grupos) : ''}
        </TabPanel>
    }

    function Grupos(grupo, g) {
        return <AccordionGrupos key={g} grupo={grupo.grupo} registros={grupo.total}  >
            {agruparTags(grupo.grupo) ? grupo.atendimentos.map(SubGrupos) :
                ordemSac === 'az' ? orderList(grupo.atendimentos, 'pessoa').map(ListaAtendimentos) : grupo.atendimentos.map(ListaAtendimentos)
            }
        </AccordionGrupos>
    }

    function SubGrupos(subGrupo, s) {
        if (listaLength(subGrupo.atendimentos) > 0)
            return <AccordionGrupos key={s} grupo={subGrupo.grpTag} registros={subGrupo.total} subGrupo={true}>
                {ordemSac === 'az' ? orderList(subGrupo.atendimentos, 'pessoa').map(ListaAtendimentos) : subGrupo.atendimentos.map(ListaAtendimentos)}
            </AccordionGrupos>
    }

    function ListaAtendimentos(atendimento) {
        return (getLayout() === 'padrao' ?
            <AccordionAtendimentos key={atendimento.codigo} codigo={codigo} showMenu={true}
                showMenuGeral={true} color={true} atendimento={atendimento} />
            :
            <Paper key={atendimento.codigo} elevation={atendimento.codigo === codigo ? 10 : 0} style={{ background: 'inherit' }}>
                <Box style={colorSac(atendimento)} sx={colorDetail(atendimento, codigo)} p={1} mb={1}>
                    <HeaderSac codigo={atendimento.codigo} atendimento={atendimento} showMenuGeral={true} onClickFiltrarVilculado={() => togglePesquisarVinc(atendimento.vinculado)} />
                </Box >
            </Paper>
        )
    }

    return (<TabContext value={value} >
        <Paper style={{ background: getTheme() === 'dark' ? '#262a34' : 'inherit', margin: '15px 10px 5px 10px' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto" style={{ background: 'inherit', borderRadius: '15px' }}
                TabIndicatorProps={{
                    sx: {
                        bgcolor: "#ed3237 !important",
                        opacity: '1'
                    }
                }}>
                {pesquisar.valor === '' ? getListSetores().map(Setores) : lista.map(Setores)}
            </TabList>
        </Paper>
        {getSetor() === 'TC' ?
            <>
                <Stack direction='row' style={{ overflowX: 'auto', overflowY: 'hidden', height: '95%' }} >
                    {listaLength(listSac[0].grupos) > 0 && listaLength(listMovimento) > 0 ? listSac[0].grupos.map(ListarColunas) : ''}
                </Stack>
                <SacDialog open={open} onClose={handleClose} />
            </>
            :
            <LayoutSac
                padrao={lista.map(TabSetores)}
                colL={lista.map(TabSetores)}
                colR={sac && codigo > 0 ?
                    <Box key={sac.codigo} p={1} >
                        {sac.codigo ?
                            <>
                                <HeaderSac codigo={codigo} showMenu={true} showMenuGeral={true} disabledDestaque={true} />
                                <BodySac />
                            </>
                            : <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                style={{ paddingTop: '25%' }}
                            ><CircularProgress style={{ color: getThemeContraste() }} /></Stack>}
                    </Box>
                    :
                    <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                }
            />
        }
    </TabContext >
    );
}