import React, { useContext, useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import TextField from '@mui/material/TextField';
import useToken from "../../hooks/useToken";
import { Divider, Paper, Typography } from "@mui/material";
import SnackbarAlert from "../../components/snackbar";
import { GeralContext } from "../../context";

export default function Login() {
    const { mensagem } = useContext(GeralContext);
    const { getToken } = useToken();
    const [data, setData] = useState({
        usuario: '',
        senha: '',
        servico: 'sac'
    })

    useEffect(() => {
        sessionStorage.clear('');
        // eslint-disable-next-line 
    }, [])

    async function submit(e) {
        e.preventDefault();
        getToken(data);
    }

    function handleData(e) {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }

    return (

        <div style={{ background: '#333' }}>
            <Box>
                <Grid container>
                    {window.innerWidth < 768 ? '' :
                        <Grid item xs={12} sm={8}>
                            <Stack
                                direction="column"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: '100vh', width: '100%' }} />
                            </Stack>
                        </Grid>
                    }
                    <Grid item xs={12} sm={4} p={5}>
                        <Paper elevation={5} >
                            <form onSubmit={(e) => submit(e)}>
                                <div style={{ minHeight: "85vh", display: "flex", alignItems: "center" }}>
                                    <Container>
                                        <Stack
                                            direction="column"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            {window.innerWidth < 768 ? <img src='./app-assets/img/logo/logo.png' alt='logo redsis' style={{ height: '20vh', width: '60%' }} /> : ''}
                                            <Typography variant="h4">LOGIN</Typography>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} >
                                                <PersonOutlineOutlinedIcon sx={{ mr: 1, my: 0.5 }} />
                                                <TextField autoFocus name="usuario" label="Login" variant="standard" fullWidth autoComplete="off" onChange={(e) => handleData(e)} />
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} >
                                                <PasswordOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                                <TextField name="senha" label="Senha" variant="standard" type="password" fullWidth autoComplete="off" onChange={(e) => handleData(e)} />
                                            </Box>
                                            <br />
                                            <Button type="submit" fullWidth variant='contained' startIcon={<LockOpenOutlinedIcon />} color="green" >
                                                Login
                                            </Button>
                                        </Stack>
                                    </Container>
                                </div>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <SnackbarAlert msg={mensagem} />
        </div >
    );
}