import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "./useToken";
import { iniConteudos } from "../utils/constructor";
import { GeralContext } from "../context";

const useConteudos = () => {
    const { token } = useToken();
    const [loadingConteudos, setLoadingConteudos] = useState(true)
    const [conteudos, setConteudos] = useState(iniConteudos);
    const { toggleMensagem } = useContext(GeralContext);

    var config = {
        headers: {
            authorization: token
        }
    };

    const putConteudo = async (parameters) => {
        let data = {
            'conteudo': parameters.conteudo
        }
        const response = await axios({
            method: 'put',
            url: `/atendimentos/${parameters.codigo}/conteudos/${parameters.idConteudo}`,
            headers: {
                authorization: token
            },
            data: data,
        });
        toggleMensagem(response.data.result, 'success');
        setLoadingConteudos(false)
    }

    const getConteudos = async (id) => {
        const response = await axios.get(`/atendimentos/${id}/conteudos`, config);
        setConteudos(response.data.result);
        setLoadingConteudos(false);
    }

    return { putConteudo, getConteudos, loadingConteudos, conteudos };
}

export default useConteudos;

