import * as React from 'react';
import { ContainerPages } from '../../components/container';
import { FormControlLabel, List, ListItem, Switch } from '@mui/material';
import useTipos from '../../hooks/useTipos';
import { TextConteudo } from '../../components/typography';
import { orderList } from '../../utils/geral';

export default function Setores() {
    const { listTipos } = useTipos();
    const [listSetor] = React.useState(localStorage.getItem('setor').split(',') || 'AT')

    function exibirSetor(codigo) {
        if (listSetor.includes(codigo)) {
            listSetor.splice(listSetor.indexOf(codigo), 1);
        } else {
            listSetor.push(codigo);
        }
        localStorage.setItem('setor', listSetor.toString());
    }

    function Setores(setor, s) {
        return (<ListItem
            key={s}
            disableGutters
        >
            <FormControlLabel control={<Switch defaultChecked={listSetor.includes(setor.codigo)} size='small' onChange={() => exibirSetor(setor.codigo)} color='green' />} />
            <TextConteudo text={setor.nome} />
        </ListItem >
        );
    }

    return (<ContainerPages activeAuxBar={true} title='Setores'>
        <List sx={{ bgcolor: 'inherit', marginLeft: '10px' }}>
            {orderList(listTipos, "nome").map(Setores)}
        </List >
    </ContainerPages>
    );
}
