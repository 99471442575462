import { filtrarLista, orderList } from "../geral";
import { setSession, getSession, getLocal, setLocal } from "../storage";
import { coalesceString, listaLength } from "../string";
import { getUser } from "../user";

let user = getUser().nome;

export const sacInitial = { codigo: undefined, tipo: getSession('tab'), pessoa: null, assunto: '', prioridade: '', usuario: '', conteudo: '', software: '', conteudos: [] };
export const iniListaAt = [{ tipo: 'AT', atendimentos: 0, grupos: [] }];

export function getSetor() {
  let setores = getListSetores();
  let setor = getSession('tab');
  if (setores) {
    if (setores.indexOf(setor) > 0) {
      return getSession('tab');
    } else {
      setSession('tab', setores[0])
      return setores[0];
    }
  } else {
    setSession('tab', 'AT')
    return 'AT';
  }
}

export function getListSetores() {
  if (getLocal('setor')) {
    return getLocal('setor').split(',');
  } else {
    setLocal('setor', 'AT')
    return ['AT'];
  }
}

export function getListAllSetores() {

  const setores = JSON.parse(getSession('tipos'));
  const listSetores = [];
  for (let i = 0; i < setores.length; i++) {
    listSetores.push(setores[i].codigo);
  }
  return listSetores;
}

export function getNomeMovimento(codigo) {
  let lista = JSON.parse(getSession('movimentos'));
  let nomMov = '';
  for (let i = 0; i < listaLength(lista); i++) {
    if (lista[i].codigo === codigo)
      nomMov = lista[i].nome;
  }
  return nomMov;
}

export function totalAt(listSac) {
  let totais = [];
  for (let i = 0; i < listSac.length; i++) {
    let totalSetor = 0
    for (let j = 0; j < listSac[i].grupos.length; j++) {
      totalSetor = listSac[i].grupos[j].atendimentos.length + totalSetor;
    }
    totais.push(totalSetor);
  }
  return totais;
}

export function coresAt(atendimento) {
  let cor = '';
  if ((atendimento.vinculado > 0) && (atendimento.vinculado !== atendimento.codigo)) {
    cor = '#00008B'; //azul
  } else if (atendimento.prioridade === 0) {
    cor = '#8B0000'; //vermelho
  } else if (atendimento.prioridade === 1) {
    cor = '#FF8C00'; //laranja
  } else if (atendimento.prioridade === 2) {
    cor = '#006400'; //verde
  }
  return cor;
}

export function colorSac(atendimento) {
  let cor = coresAt(atendimento);
  return { border: `1px solid ${cor}`, borderLeft: `12px solid ${cor}`, borderRadius: '5px', background: 'inherit', cursor: 'pointer' };
}

export function colorBorder(atendimento) {
  let cor = coresAt(atendimento);
  return { border: `2px solid ${cor}`, borderRadius: '0 0 5px 5px', background: 'inherit', cursor: 'pointer' };
}

export function colorBorderKanban(atendimento) {
  let cor = coresAt(atendimento);
  return `7px solid ${cor}`;
}

export function colorDetail(atendimento, idSac) {
  let cor = coresAt(atendimento);
  return { borderLeft: idSac === atendimento.codigo ? `25px solid ${cor} !important` : '0px' };
}

export function ordemSac(lista) {
  for (let i = 0; i < lista.length; i++) {
    lista[i].grupos = grpAtendimentos(lista[i].atendimentos, lista[i].tipo);
    lista[i].atendimentos = listaLength(lista[i].atendimentos);
  }
  return lista.reverse();
}

export function getPendentes(lista) {
  let novaLista = [];
  for (let at = 0; at < listaLength(lista); at++) {
    if ((lista[at].status === 'P' || lista[at].status === 'L' || lista[at].status === 'R') && lista[at].origem === '' && lista[at].tipo === getSetor()) {
      novaLista.push(lista[at]);
    }
  }
  return novaLista.reverse();
}

export function ListTags(lista) {
  let novaLista = [];
  for (let j = 0; j < lista.length; j++) {
    novaLista.push({ nome: lista[j].nome, check: false })
  }
  return (orderList(novaLista, 'nome'));
}

export function grpAtendimentos(lista, setor) {
  let meusAtendimentos = []
  let sendoAtendidos = [];
  let retornados = [];
  let aguardando = [];
  let listTags = [];

  for (let at = 0; at < listaLength(lista); at++) {
    let tag = coalesceString(lista[at].tags, 'Outros atendimentos');
    if (listTags.indexOf(tag) < 0) {
      let listaTags = tag.split(',')
      if (listTags.indexOf(listaTags[0]) < 0)
        listTags.push(listaTags[0])
    }
    if ((lista[at].status === 'P' || lista[at].status === 'L' || lista[at].status === 'R') && lista[at].origem === '') {
      aguardando.push(lista[at]);
    } else
      if (lista[at].origem === 'R' && lista[at].status !== 'A') {
        retornados.push(lista[at]);
      } else
        if (lista[at].status === 'A' && user !== lista[at].atendpref) {
          sendoAtendidos.push(lista[at]);
        } else
          if (lista[at].status === 'A' && user === lista[at].atendpref) {
            meusAtendimentos.push(lista[at]);
          }
  }

  orderTags(listTags);
  switch (setor) {
    case 'AN':
      return [
        { grupo: 'Meus atendimentos', atendimentos: orderGrpAN(meusAtendimentos, listTags), total: listaLength(meusAtendimentos) },
        { grupo: 'Sendo atendidos', atendimentos: orderGrpAN(sendoAtendidos, listTags), total: listaLength(sendoAtendidos) },
        { grupo: 'Retornados', atendimentos: retornados, total: listaLength(retornados) },
        { grupo: 'Aguardando', atendimentos: orderGrp(aguardando), total: listaLength(aguardando) }
      ];
    case 'TC':
      return orderGrpTC(lista, JSON.parse(getSession('movimentos')), 'TC');
    case 'PG':
      return [
        { grupo: 'Meus atendimentos', atendimentos: orderDataAtendimento(meusAtendimentos), total: listaLength(meusAtendimentos) },
        { grupo: 'Sendo atendidos', atendimentos: sendoAtendidos, total: listaLength(sendoAtendidos) },
        { grupo: 'Retornados', atendimentos: retornados, total: listaLength(retornados) },
        { grupo: 'Aguardando', atendimentos: orderGrpAN(aguardando, listTags), total: listaLength(aguardando) }
      ];
    case 'MT':
      return [
        { grupo: 'Meus atendimentos', atendimentos: orderDataAtendimento(meusAtendimentos), total: listaLength(meusAtendimentos) },
        { grupo: 'Sendo atendidos', atendimentos: sendoAtendidos, total: listaLength(sendoAtendidos) },
        { grupo: 'Retornados', atendimentos: retornados, total: listaLength(retornados) },
        { grupo: 'Aguardando', atendimentos: orderGrpAN(aguardando, listTags), total: listaLength(aguardando) }
      ];
    default:
      return [
        { grupo: 'Meus atendimentos', atendimentos: meusAtendimentos, total: listaLength(meusAtendimentos) },
        { grupo: 'Sendo atendidos', atendimentos: sendoAtendidos, total: listaLength(sendoAtendidos) },
        { grupo: 'Retornados', atendimentos: retornados, total: listaLength(retornados) },
        { grupo: 'Aguardando', atendimentos: aguardando, total: listaLength(aguardando) }
      ];
  }
}

function orderGrp(lista) {
  lista.sort((a, b) => {
    if (a.prioridade > b.prioridade && a.data_agendado > b.data_atendimento) {
      return 1;
    }
    if (a.prioridade < b.prioridade) {
      return -1;
    }
    if (a.prioridade === b.prioridade && a.data_agendado > b.data_atendimento) {
      return 1
    }
    if (a.prioridade === b.prioridade && a.data_agendado < b.data_atendimento) {
      return -1
    } else {
      return 0
    }
  })
  return lista;
}

function orderDataAtendimento(lista) {
  lista.sort((a, b) => {
    if (a.data_atendimento > b.data_atendimento) {
      return 1;
    }
    if (a.data_atendimento < b.data_atendimento) {
      return -1;
    } else {
      return 0;
    }
  })
  return lista;
}

function orderTags(lista) {
  lista.sort((a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0
  })
  return lista
}

function orderGrpAN(lista, tags) {
  let novaLista = [];

  novaLista = orderGrp(lista);

  novaLista = lista.sort((a, b) => {
    if (a.tags < b.tags) {
      return 1;
    }
    if (a.tags > b.tags) {
      return -1;
    }
    return 0
  });
  let grpTags = [];
  let listAt = [];
  for (let i = 0; i < tags.length; i++) {
    for (let j = 0; j < novaLista.length; j++) {
      let tag = novaLista[j].tags.split(',')[0]
      if (tags[i] === (tag === '' ? 'Outros atendimentos' : tag)) {
        listAt.push(novaLista[j]);
      }
    }
    grpTags.push({ "grpTag": tags[i], "atendimentos": listAt, "total": listAt.length })
    listAt = [];
  }
  return grpTags;
}

export function orderGrpTC(lista, movimentos, sigla) {
  let listaAt = [];
  let listMov = [];
  listaAt = lista;
  listMov = listaLength(movimentos) > 0 ? movimentos : [];

  listMov = filtrarLista(listMov, 'sigla', sigla);
  listMov = orderList(listMov, 'ordem');
  listMov = orderList(listMov, 'nome');

  let grpMov = [];
  let listAt = [];
  let total = 0;

  for (let i = 0; i < listMov.length; i++) {
    for (let j = 0; j < listaAt.length; j++) {
      listaAt[j].movimentoGrp = listaAt[j].movimento === 0 || listaAt[j].status === 'A' ? 15 : listaAt[j].movimento;
      if (listaAt[j].movimentoGrp === listMov[i].codigo) {
        listAt.push(listaAt[j]);
        total = total + 1;
      }
    }
    grpMov.push({ "grupo": listMov[i].nome, "atendimentos": listAt, "total": listAt.length, codigo: listMov[i].codigo })
    listAt = [];
  }

  let pend = [];
  let atend = [];

  if (listaLength(grpMov[0]) !== 0) {
    for (let j = 0; j < grpMov[0].atendimentos.length; j++) {
      if (grpMov[0].atendimentos[j].status === 'A')
        atend.push(grpMov[0].atendimentos[j]);
      else
        pend.push(grpMov[0].atendimentos[j]);
    }
  }

  if (grpMov[0]) {
    if (grpMov[0].grupo === 'Abertos') {
      grpMov[0].grupos = [
        { grupo: 'Aguardando', atendimentos: pend, "total": pend.length },
        { grupo: 'Sendo atendidos', atendimentos: atend, "total": atend.length }
      ];
      grpMov[0].atendimentos = [];
    }
  }
  return grpMov;
}


export function agruparTags(grupo) {
  if (
    (getSetor() === 'AN' && (grupo !== 'Aguardando' && grupo !== 'Retornados')) ||
    (getSetor() === 'TC' && (grupo !== 'Retornados')) ||
    (getSetor() === 'PG' && (grupo === 'Aguardando')) ||
    (getSetor() === 'MT' && (grupo === 'Aguardando'))
  ) {
    return true;
  } else {
    return false
  }
}

export function expandedGrp(grupo, registros, subGrupo) {
  if (
    (
      registros > 0 && (grupo === 'Aguardando' | grupo === 'Meus atendimentos' | grupo === 'Finalizados' |
        (grupo === 'Retornados' && getSetor() === 'PG'))
    )
    ||
    subGrupo
  ) {
    return true;
  } else
    return false;
}

export function verificarOutrosAt(lista, codigo, codpessoa) {
  try {
    let listaAt = lista[0].grupos[3].atendimentos;
    let cont = 0;
    for (let i = 0; i < listaLength(listaAt); i++) {
      if ((listaAt[i].codpessoa === codpessoa) && (listaAt[i].codigo !== codigo)) {
        cont = cont + 1;
      }
    }
    return cont > 0;
  } catch {
    return 0;
  }
}

export function obgAssunto(codigo) {
  let lista = [];
  let assunto = {};
  try {
    lista = JSON.parse(getSession('assuntos'));
    assunto = lista.filter((itens) => itens.codigo === codigo);
    return assunto[0].troca_obrigatoria;
  } catch {
    return 0;
  }
}
