import { useState, useEffect } from "react";
import axios from "../services/api";
import useToken from "./useToken";


const useUsuarios = () => {
    const { token } = useToken();
    const [listUsuarios, setListUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);

    var config = {
        headers: {
            authorization: token
        }
    };

    //#region RETORNA LISTA DE SETORES ******************************************************************************************************************************************************************************************************
    const getlistUsuarios = async () => {
        try {
            const response = await axios.get('/usuarios', config);
            setListUsuarios(response.data.result);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }
    //#endregion *********************************************************************************************************************************************************************************************************************************

    useEffect(() => {
        getlistUsuarios();
        // eslint-disable-next-line
    }, [])

    return { listUsuarios, loading };
}

export default useUsuarios;

