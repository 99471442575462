import * as React from 'react';
import Typography from '@mui/material/Typography';
import { getLayout, getThemeContraste } from '../../utils/theme';

export function Title(props) {
    return (<Typography fontSize='15px' fontWeight='bold' style={{ color: getThemeContraste() }}>
        {props.title}
    </Typography>)
}

export function SubTitle(props) {
    return (<Typography fontSize={12} noWrap style={{ color: getThemeContraste(), letterSpacing: '1px' }}>
        {props.Title}{props.subTitle}
    </Typography>)
}

export function ObsCliente(props) {
    return (<Typography fontSize={12} style={{ color: getThemeContraste(), letterSpacing: '1px' }}>
        {props.Title}{props.subTitle}
    </Typography>)
}

export function TextConteudo(props) {
    function TextoComQuebraDeLinha(texto) {
        try {
            const linhas = texto.split('\n').map((linha, index) => (
                <React.Fragment key={index}>
                    {linha}
                    <br />
                </React.Fragment>
            ));

            return <>{linhas}</>;
        } catch (error) {
            return ''
        }
    }
    return <Typography fontSize={13} style={{ wordWrap: 'break-word', color: getThemeContraste(), letterSpacing: '1px', fontWeight: '360' }}>{TextoComQuebraDeLinha(props.text)}</Typography>;
}
export function TextDescricao(props) {
    return <Typography fontSize={12} style={{ wordBreak: 'break-word', color: getThemeContraste(), letterSpacing: '1px' }}>{props.text}</Typography>;
}

export function TextDescricaoLarge(props) {
    return <Typography fontSize={15} style={{ wordBreak: 'break-word', color: getThemeContraste(), letterSpacing: '1px' }}>{props.text}</Typography>;
}

export function TextMarcadores(props) {
    return <Typography fontSize={12} style={{ color: getThemeContraste() }}>{props.text}</Typography>;
}


export function TitleGrupos(props) {
    return (<Typography fontSize={15} noWrap style={{ color: getThemeContraste() }}>
        {props.title}
    </Typography>)
}

export function TitleSmall(props) {
    return (<Typography fontSize={13} noWrap style={{ color: getThemeContraste(), fontWeight: '400', letterSpacing: '1px' }}>
        {props.title}
    </Typography>)
}

export function SubTitleSmall(props) {
    return (<Typography fontSize={12} noWrap style={{ color: props.color && getLayout() === 'Email' ? props.color : getThemeContraste(), letterSpacing: '1px' }} >
        <b>{props.Title}</b>{props.subTitle}
    </Typography>)
}