import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { colorSac, expandedGrp } from '../../utils/sac';
import { TitleGrupos, TitleSmall } from '../typography';
import { BookMark } from '../icons';
import { StackJustify, StackLeft } from '../../components/stack';
import { HeaderSac } from '../sac/header';
import { BodySac } from '../sac/body';
import { SacContext } from '../../context';
import { getTheme } from '../../utils/theme';

//#region AccordionGrupos ******************************************************************************************************************************************************************************************************
export function AccordionGrupos(props) {
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(expandedGrp(props.grupo, props.registros, props.subGrupo))
    }, [props.registros, props.grupo])

    return (<Paper style={{ background: 'inherit', margin: '10px' }}>
        <Accordion expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            style={getTheme() === 'dark' ?
                { background: props.subGrupo ? "#2c303b" : '#262a34' } :
                { background: props.subGrupo ? "#f1f1f1" : '#f1f1f1' }
            }
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${props.index}Grupo-content`}
                onClick={() => setExpanded(!expanded)}
            >
                <Grid container>
                    <Grid item xs={12} sm={8}>
                        <StackLeft>
                            {props.subGrupo ? <BookMark fontSize='13' /> : ''}<TitleGrupos title={props.grupo} />
                        </StackLeft>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlignLast: 'end' }}>
                        <TitleGrupos title={props.registros !== undefined ? props.registros + ' registro(s)' : ''} />
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 5px 20px 10px' }} >
                {expanded ? props.children : ''}
            </AccordionDetails>
        </Accordion>
    </Paper >
    );
}
//#endregion AccordionGrupos *******************************************************************************************************************************************************************************************************************

//#region AccordionGruposTC ******************************************************************************************************************************************************************************************************
export function AccordionGruposTC(props) {
    return (<Paper style={{ background: 'inherit', margin: '10px' }}>
        <Accordion expanded={true}
            style={getTheme() === 'dark' ?
                { background: props.subGrupo ? "#2c303b" : '#262a34' } :
                { background: props.subGrupo ? "#f1f1f1" : '#f1f1f1' }
            }
        >
            <AccordionSummary
                aria-controls={`panel${props.index}Grupo-content`}
            >
                <div style={{ textAlign: 'justify' }}>
                    <TitleGrupos title={props.grupo} />
                    <TitleSmall title={(props.registros + ' registros')} />
                </div>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 5px 20px 10px' }} >
                <Divider />
                <br />
                {props.children}
            </AccordionDetails>
        </Accordion>
    </Paper >
    );
}
//#endregion AccordionGruposTC *******************************************************************************************************************************************************************************************************************

// //#region AccordionAtendimentos ******************************************************************************************************************************************************************************************************
export function AccordionAtendimentos(props) {
    const atendimento = props.atendimento;
    const { codigo } = React.useContext(SacContext);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(codigo ? codigo === atendimento.codigo : false);
    }, [codigo])

    return (
        <Box p={0} m={0} mb={1}>
            <Accordion expanded={expanded}
                id={atendimento.codigo + '-' + atendimento.tipo}
                aria-labelledby={atendimento.codigo + '-' + atendimento.tipo}
                style={colorSac(atendimento)}
            >
                <AccordionSummary
                    id={`panel${atendimento.codigo}a-header`}
                    aria-controls={`panel${atendimento.codigo}a-content`}
                >
                    <HeaderSac
                        atendimento={atendimento}
                        codigo={atendimento.codigo}
                        status={atendimento.status}
                        showMenu={atendimento.status !== 'F'}
                        showMenuGeral={atendimento.status !== 'F'}
                    />
                </AccordionSummary>
                {expanded && codigo === atendimento.codigo ?
                    <AccordionDetails style={{ padding: '0 5px', background: 'inherit' }}>
                        <Divider style={{ margin: '0px 10px 0px 10px' }} />
                        <BodySac />
                    </AccordionDetails>
                    : ''}
            </Accordion>
        </Box >
    );
}