import * as React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonConcluido, ButtonFechar } from '../../../../button';
import useRastreios from '../../../../../hooks/useRastreios';
import { SacContext } from '../../../../../context';
import { TitleGrupos } from '../../../../typography';
import { getThemeContraste } from '../../../../../utils/theme';

export default function DialogRastreio(props) {
    const { getRastreios, rastreios, loading } = useRastreios();
    const { addRemoveRastreio } = useRastreios()
    const [item, setItem] = React.useState('');
    const { toggleAcao, codigo } = React.useContext(SacContext)

    React.useEffect(() => {
        getRastreios();
        // eslint-disable-next-line
    }, [])

    function getItem(item) {
        setItem(item);
    }

    function addRastreio() {
        addRemoveRastreio(codigo, item.codigo, 'post');
        toggleAcao(codigo, 'sac')
    }

    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={item.descricao} onClick={() => getItem(item)}>
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }


    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Rastreios' /></Divider>
            <Box pr={3}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={['0']}
                    style={{ color: getThemeContraste() }}
                >
                    <TreeItem nodeId={'0'} label='Rastreios' >
                        {loading ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                    </TreeItem>
                </TreeView>
                <p />
                <Stack direction='row' spacing={1} justifyContent='flex-end'>
                    <ButtonConcluido onClick={() => addRastreio()} />
                    <ButtonFechar />
                </Stack>
                <p />
            </Box>
        </>
    );
}
