import { listaLength } from "../string";

export function orderList(lista, campo) {
    return lista.sort((a, b) => {
        if (a[campo] > b[campo]) {
            return 1;
        }
        if (a[campo] < b[campo]) {
            return -1;
        } else {
            return 0;
        }
    });
}

export function filtrarLista(lista, filtro, valor) {
    let listaNova = [];
    for (let i = 0; i < listaLength(lista); i++) {
        if (lista[i][filtro] === valor) {
            listaNova.push(lista[i]);
        }
    }
    return listaNova;

}

export function verificarRota(valor) {
    let path = window.location.pathname.toLowerCase();
    return path.indexOf(valor) > 0;
}
