import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";
import { ListTags } from "../utils/sac";

const useTag = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(true)
    const [listTags, setListTags] = useState([]);
    const { toggleMensagem, toggleAtualizar } = useContext(GeralContext);

    const addRemove = async (nome, method) => {
        setLoading(true);
        let data = { 'tag': nome };
        if (method === 'post') {
            const response = await axios({
                method: method,
                url: `/atendimentos/tags`,
                headers: {
                    authorization: token
                },
                data: data,
            });
            setLoading(false);            
                 toggleMensagem(response.data.result, 'success');
        } else {
            let tag = nome;
            const response = await axios({
                method: method,
                url: `/atendimentos/tags/${tag}`,
                headers: {
                    authorization: token
                },
            });
            setLoading(false);
            toggleAtualizar(true);
            toggleMensagem(response.data.result, 'error');
        }
    }

    const tagSac = async (id, tag, method, hiddenMsg) => {
        let data = { 'tag': tag };
        setLoading(true);
        if (method === 'POST') {
            const response = await axios({
                method: method,
                url: `/atendimentos/${id}/tagsac`,
                headers: {
                    authorization: token
                },
                data: data
            });
            if (!hiddenMsg)
            toggleMensagem(response.data.result, 'success');
        } else {
            const response = await axios({
                method: method,
                url: `/atendimentos/${id}/tagsac/${encodeURI(tag)}`,
                headers: {
                    authorization: token
                },
            });
            if (!hiddenMsg)
            toggleMensagem(response.data.result, 'error');
        }
        setLoading(false);
    }


    const getTags = async () => {
        setLoading(true);
        var config = {
            headers: {
                authorization: token
            }
        };
        const response = await axios.get('/atendimentos/tags', config);
        setListTags(ListTags(response.data.result));
        setLoading(false)
    }


    return { loading, listTags, getTags, tagSac, addRemove };
}

export default useTag;