import * as React from 'react';
import { createContext, useState } from 'react';
import { iniPesquisa } from '../utils/constructor';
import useSac from '../hooks/useSac';
import { getSession, setSession } from '../utils/storage';
import { getSetor, verificarOutrosAt } from '../utils/sac';

export const GeralContext = createContext();

export const GeralProvider = ({ children }) => {
    const [mensagem, setMensagem] = useState(undefined);
    const [ativarPesquisa, setAtivarPesquisa] = useState(false);
    const [pesquisar, setPesquisar] = useState(iniPesquisa);
    const [atualizar, setAtualizar] = useState(false);
    const [ordemSac, setOrdemSac] = useState('padrao');

    React.useEffect(() => {
        setTimeout(() => {
            setMensagem(undefined)
        }, 5000);
    }, [mensagem]);

    const toggleAtualizar = (at) => {
        setAtualizar(at);
    }

    const togglePesquisar = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setPesquisar((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const togglePesquisarVinc = (codigo) => {
        toggleAtivarPesquisa();
        setPesquisar({
            filtro: 'Referencia',
            valor: codigo
        })
        setAtivarPesquisa(true);
        toggleAtualizar(true);
    }

    const clearPesquisar = () => {
        setPesquisar((prevalue) => {
            return {
                ...prevalue,
                valor: ''
            }
        })
        toggleAtualizar(true);
    }

    const toggleAtivarPesquisa = () => {
        setAtivarPesquisa(!ativarPesquisa);
        clearPesquisar();
    }

    const toggleDesativarPesquisa = () => {
        setAtivarPesquisa(false);
        clearPesquisar();
    }

    const clickPesquisar = (e) => {
        toggleAtualizar(true)
        e.preventDefault();
    }

    const heightDefault = () => {
        let ajuste = ativarPesquisa ? -160 : -95;
        return visualViewport.height + ajuste;
    }

    const toggleMensagem = (mensagem, severity) => {
        if (mensagem)
            setMensagem({ mensagem: mensagem, severity: severity });
    }

    const toggleOrdemSac = (ordem) => {
        setOrdemSac(ordem);
        toggleAtualizar(true);
    }

    return (
        <GeralContext.Provider value={{
            pesquisar, togglePesquisar,
            clickPesquisar,
            ativarPesquisa, toggleAtivarPesquisa, toggleDesativarPesquisa, clearPesquisar,
            heightDefault,
            mensagem, toggleMensagem,
            atualizar, toggleAtualizar,
            togglePesquisarVinc,
            toggleOrdemSac, ordemSac
        }}>
            {children}
        </GeralContext.Provider>
    );
}

export const SacContext = createContext();

export const SacProvider = ({ children }) => {
    const { getSac, getListSac, listSac, getData, sac, putSac } = useSac();
    const [setor, setSetor] = useState(getSetor());
    const [codigo, setCodigo] = useState(0);
    const [acao, setAcao] = useState('sac');
    const { atualizar, pesquisar } = React.useContext(GeralContext);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (window.location.pathname === '/sac' && acao === 'sac')
                getData();
        }, 8000 + Math.floor(Math.random() * 100) + 1);
        return () => clearInterval(interval);
    }, [])


    React.useEffect(() => {
        getListSac(pesquisar);
        getSac(codigo);
    }, [getSession('atualizar'), setor, atualizar === true]);

    const toggleSetor = (setor) => {
        setSession('tab', setor);
        setSetor(setor);
        setCodigo(0)
    }

    const toggleOpen = (status) => {
        setOpen(status)
    }

    const toggleCodigo = (id) => {
        setCodigo(0);
        if (id !== codigo) {
            setCodigo(id);
        }
        if (id > 0) {
            getSac(id);
        }
        setAcao('sac');
    }
    const toggleAcao = (id, acao, codpessoa) => {
        setCodigo(id);
        if (acao !== 'atender')
            setAcao(acao);
        
        getSac(id);
        
        if (acao === 'sac')
            getListSac();

        if ((acao === 'atender') && (codpessoa > 0)) {
            let outrosAt = verificarOutrosAt(listSac, id, codpessoa);
            putSac('atender', { codigo: id, outrosAt: outrosAt })
        }


    }

    return (
        <SacContext.Provider value={{
            setor, toggleSetor,
            codigo, toggleCodigo,
            acao, toggleAcao,
            listSac, sac,
            open, toggleOpen
        }}>
            {children}
        </SacContext.Provider>
    );
}
