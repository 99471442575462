import * as React from 'react';
import { getSetor, orderGrpTC } from '../../utils/sac';
import { Stack } from '@mui/material';
import { listaLength } from '../../utils/string';
import { GeralContext, SacContext } from '../../context';
import { BoxAtendimentoTC, BoxGruposTC, BoxSubGruposTC } from '../../components/box';
import { ContainerPages } from '../../components/container';
import { SacDialog } from '../../components/dialog';
import useMovimento from '../../hooks/useMovimentos';

export default function TestComplete() {
    const [at, setAt] = React.useState(true);
    const { getMovimentos, listMovimento, putMovimentoAt } = useMovimento();
    const { toggleAtualizar } = React.useContext(GeralContext);
    const { listSac, toggleSetor, toggleCodigo, open, toggleOpen } = React.useContext(SacContext);

    React.useEffect(() => {
        if (getSetor() === 'TC') {
            getMovimentos(undefined, true);
            setAt(false);
        }
    }, [at])

    function drag(ev, sourceColumnId) {
        ev.dataTransfer.setData("text", ev.target.id);
        ev.dataTransfer.setData("sourceColumnId", sourceColumnId);
    }

    const allowDrop = (ev) => {
        ev.preventDefault();
    }

    const drop = async (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        var idSac = data;
        var destino = ev.target.id;
        await putMovimentoAt(idSac, destino);
        toggleAtualizar(true)
    }

    function showSac() {
        toggleOpen(true);
    }

    const handleClose = () => {
        toggleOpen(false);
    };

    function ListarColunas(grupo, g) {
        return <BoxGruposTC key={g} id={grupo.codigo} grupo={grupo.grupo} registros={grupo.total} onDrop={(e) => drop(e, grupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(grupo.grupos) > 0 ? grupo.grupos.map(ListSubColunas) : listaLength(grupo.atendimentos) > 0 ? grupo.atendimentos.map(ListarItens) : ''}
        </BoxGruposTC>
    }

    function ListSubColunas(subGrupo, s) {
        return <BoxSubGruposTC key={s} id={subGrupo.grupo} grupo={subGrupo.grupo} registros={subGrupo.total} onDrop={(e) => drop(e, subGrupo.grupo)} onDragOver={(e) => allowDrop(e)}>
            {listaLength(subGrupo.atendimentos) > 0 ? subGrupo.atendimentos.map(ListarItens) : ''}
        </BoxSubGruposTC>
    }

    function ListarItens(atendimento) {
        return <BoxAtendimentoTC key={atendimento.codigo} atendimento={atendimento} onDragStart={(e) => drag(e, atendimento.tags)} onDoubleClick={() => showSac()} onClick={() => toggleCodigo(atendimento.codigo)} />;
    }

    return (<ContainerPages activeAuxBar={true} title='TestComplete'>
        <Stack direction='row' style={{ overflowX: 'auto', overflowY: 'hidden', height: '95%' }} >
            {listaLength(orderGrpTC(listSac, listMovimento, 'TC')[0].grupos) > 0 && listaLength(listMovimento) > 0 ? orderGrpTC(listSac, listMovimento, 'TC')[0].grupos.map(ListarColunas) : ''}
        </Stack>
        <SacDialog open={open} onClose={handleClose} />
    </ContainerPages>
    );
}