import * as React from 'react';
import { Box, Button } from '@mui/material';
import { ContainerPages } from '../../components/container';
import { StackJustify, StackRight } from '../../components/stack';
import { SelectTags } from '../../components/select';
import { SearchIcon, IconMark } from '../../components/icons';
import { TextConteudo } from '../../components/typography';
import { SacContext } from '../../context';
import { listaLength } from '../../utils/string';
import useTag from '../../hooks/useTag';

export default function MudarTags() {
    const [oldTag, setOldTag] = React.useState('');
    const [newTag, setNewTag] = React.useState('');
    const [qtdeAtd, setQtdeAtd] = React.useState(0);
    const { listSac } = React.useContext(SacContext);
    const [listaAt, setListaAt] = React.useState([]);
    const { tagSac } = useTag();

    const handleChangeOld = (event) => {
        let value = event.target.value;
        setOldTag(value);
    }

    const handleChangeNew = (event) => {
        let value = event.target.value;
        setNewTag(value);
    }

    const buscarAt = () => {
        let indexGrp = 0;
        let lista = [];
        if (listaLength(listSac) > 0) {
            for (let i = 0; i < listaLength(listSac[0].grupos[indexGrp].atendimentos); i++) {
                if (listSac[0].grupos[indexGrp].atendimentos[i].grpTag === oldTag) {
                    for (let j = 0; j < listaLength(listSac[0].grupos[indexGrp].atendimentos[i].atendimentos); j++) {
                        lista.push(listSac[0].grupos[indexGrp].atendimentos[i].atendimentos[j].codigo);
                    }
                }
            }
        }
        setListaAt(lista);
    }

    const alterarTag = () => {
        for (let i = 0; i < listaLength(listaAt); i++) {
            tagSac(listaAt[i], oldTag, 'DELETE');
            setTimeout(() => {
                tagSac(listaAt[i], newTag, 'POST');
            }, 1500);
            setQtdeAtd(i + 1)
        }
    }

    return (
        <ContainerPages activeAuxBar={true} title='Substituir Tags'>
            <Box p={1}>
                <TextConteudo text='Substituir: ' />
                <SelectTags onChange={handleChangeOld} value={oldTag} />
                <p />
                <TextConteudo text='por: ' />
                <SelectTags onChange={handleChangeNew} value={newTag} />
                <p />
                <StackJustify>
                    <TextConteudo text={`Alterados: ${qtdeAtd} de ${listaLength(listaAt)}`} />
                    <StackRight>
                        <Button variant='contained' size='small' color='blue' onClick={buscarAt}><StackJustify> <SearchIcon /> Buscar Atendimentos</StackJustify></Button>
                        <Button variant='contained' size='small' color='green' onClick={alterarTag}><StackJustify><IconMark /> Alterar Tags</StackJustify></Button>
                    </StackRight>
                </StackJustify>
            </Box>
        </ContainerPages >
    );
}

