import { useContext, useState } from "react";
import axios from "../services/api";
import { getSession, setSession } from "../utils/storage";
import { GeralContext } from "../context";


const useToken = () => {
    const token = 'Bearer ' + getSession('token_sac');
    const [auth, setAuth] = useState(false);
    const { toggleMensagem } = useContext(GeralContext);

    async function getTokenExterno() {
        sessionStorage.clear();
        let data = {
            usuario: '373197efaadf4966bfa0e0f0fc8c29c4',
            senha: '5b2eb16578424595a79ebf1f9a262846',
            servico: 'externo',
        }
        await axios.post('/GetToken', data)
            .then(function (response) {
                setSession('token_sac', JSON.stringify(response.data.token).replace(/"/g, ''))
                setAuth(true);
            })
            .catch(function (error) {
                toggleMensagem(error.response.status + ' - ' + error.response.data.error, 'error');
            })
    }

    async function getTokenAnexosSac() {
        sessionStorage.clear();
        let data = {
            usuario: '974c8fca43fb47eea53feee2b58b40cb',
            senha: '682A2050D49D46B990D497D64C27E99B',
            servico: 'anexossac',
        }
        await axios.post('/GetToken', data)
            .then(function (response) {
                setSession('token_sac', JSON.stringify(response.data.token).replace(/"/g, ''))
                setAuth(true);
            })
            .catch(function (error) {
                toggleMensagem(error.response.status + ' - ' + error.response.data.error, 'error');
            })
    }


    async function getToken(params) {
        sessionStorage.clear();
        let data = {
            usuario: params.usuario,
            senha: params.senha,
            servico: 'atendimentos',
        }
        await axios.post('/GetToken', data)
            .then(function (response) {
                setSession('token_sac', JSON.stringify(response.data.token).replace(/"/g, ''))
                window.location.href = '/sac';
            })
            .catch(function (error) {
                toggleMensagem(error.response.status + ' - Usuário inválido! Verifique o usuário e a senha.', 'error');
            })
    }

    return { getToken, getTokenExterno, getTokenAnexosSac, token, auth }
}
export default useToken;