import React, { useState } from 'react';
import JoditEditor from 'jodit-react';
import { TextFieldButton, TextFieldDefault } from '../../components/textfield';
import { iniFaq } from '../../utils/constructor';
import { ContainerPages } from '../../components/container';
import useRastreios from '../../hooks/useRastreios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { getThemeContraste } from '../../utils/theme';
import { TextConteudo } from '../../components/typography';
import { Check, Delete } from '../../components/icons';
import { ButtonConcluido, ButtonIcon } from '../../components/button';

function Faq() {
    const [content, setContent] = useState('');
    const [faq, setFaq] = useState(iniFaq);
    const { getRastreios, rastreios, loading } = useRastreios();
    const [item, setItem] = React.useState({ codigo: 0, descricao: '' });

    const config = {
        readonly: false
    };

    React.useEffect(() => {
        getRastreios();
    }, [])

    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={item.descricao} onClick={() => setItem(item)}>
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    const handleUpdateContent = (newContent) => {
        setContent(newContent);
    };

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        setFaq((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const handleSave = () => {
        console.log({
            "titulo": faq.titulo,
            "descricao": content,
            "tipo": faq.tipo,
            "rastreio": faq.rastreio
        })
    }

    return (
        <ContainerPages activeAuxBar={true} title='FAQ'>
            <TextFieldDefault id='titulo' name='titulo' onChange={handleChange} value={faq.titulo} autoFocus={true} label='Titulo' />
            <TextFieldDefault id='tipo' name='tipo' onChange={handleChange} value={faq.tipo} autoFocus={true} label='Tipo' />
            <Box p={1}>
                <TextConteudo text='Descrição' />
                <JoditEditor
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={handleUpdateContent} // atualiza o conteúdo quando o editor perde o foco
                />
            </Box>
            <Box p={1}>
                {faq.rastreio.codigo > 0 ? <TextFieldButton id='rastreio' name='rastreio' value={faq.rastreio.descricao} label='Rastreio' icon={<Delete color='red' />} onClick={() => setFaq((prevalue) => { return { ...prevalue, 'rastreio': { codigo: 0, descricao: '' } } })} /> :
                    <>
                        <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            <Box p={1}>
                                <TreeItem nodeId={'0'} label='Rastreios' onClick={() => setItem({ codigo: 0, descricao: 'Rastreios', master: 0 })} style={{ color: getThemeContraste() }}>
                                    {loading ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                                </TreeItem>
                            </Box>
                        </TreeView>
                        <Divider /><p/>
                        <ButtonIcon caption='Selecionar Rastreio' onClick={() => setFaq((prevalue) => { return { ...prevalue, 'rastreio': item } })} icon={<Check />} color='blue' />
                    </>}
            </Box>
            <div style={{textAlign: 'end'}}>
            <ButtonConcluido onClick={handleSave} />
            </div>
            <p/>
        </ContainerPages>
    );
}

export default Faq;
