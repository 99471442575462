import { Divider, Stack, Switch } from '@mui/material';
import * as React from 'react';
import { ButtonFechar } from '../../../../button';
import SacResumo from '../../../resumo';
import useSac from '../../../../../hooks/useSac';
import { TextConteudo, TitleGrupos, TitleSmall } from '../../../../typography';
import { getPendentes } from '../../../../../utils/sac';
import { getUser } from '../../../../../utils/user';
import { StackLeft } from '../../../../stack';
import { listaLength } from '../../../../../utils/string';
import { SacContext } from '../../../../../context';

export default function DialogOutros(props) {
    const { getOutros, listOutros } = useSac();
    const [todos, setTodos] = React.useState(false);
    const { codigo } = React.useContext(SacContext);

    React.useEffect(() => {
        getOutros(props.codpessoa)
    }, [])


    function ListaAtendimentos(atendimento, a) {
        return codigo === atendimento.codigo ? '' : <SacResumo key={a} atendimento={atendimento} color={true} atender={atendimento.status === 'P'} />
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Outros Atendimentos' /></Divider>
            <StackLeft><Switch size='small' value={getUser().sub} onChange={() => setTodos(!todos)} /><TitleSmall title='Todos' /></StackLeft><p />
            <Stack spacing={1} paddingRight={1} paddingBottom={1}>
                {listaLength(listOutros) > 0 ?
                    todos ? listOutros.map(ListaAtendimentos) : getPendentes(listOutros).map(ListaAtendimentos).reverse()
                    : <TextConteudo text='Nenhum atendimento encontrado' />}
                <Stack direction='row' spacing={1} justifyContent='flex-end' >
                    <ButtonFechar />
                </Stack>
            </Stack>
        </>
    );
}
