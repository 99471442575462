import { getSetor } from "../sac";

export const iniAtendimento = {
    codigo: undefined,
    tipo: getSetor(),
    pessoa: null,
    assunto: '',
    prioridade: 2,
    usuario: '',
    contato: '',
    conteudo: '',
    software: 'REDSIS 4.1.0',
    conteudos: [],
    caminhobd: ''
};

export const iniFaq = {
    titulo: '',
    descricao: '',
    tipo: '',
    rastreio: { codigo: 0, descricao: '' }
};

export const iniPesquisa = { filtro: "Cliente", valor: "" };

export const iniConteudos = [{ codigo: 0, titulo: '', conteudo: '' }];

export const iniMensagem = { mensagem: '', severity: '' };

export const iniAnexos = [{
    nome: "",
    tamanho: 0,
    data: ""
}]

export const iniMensagemFinal = { tag: '', solucao: '', versao: '' }

export const iniMovimento = { codigo: 0, nome: '', sigla: 'AN', ordem: 0 }