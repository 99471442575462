import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid } from '@mui/material';
import useTelefone from '../../hooks/useTelefone';
import { SelectClientes } from '../../components/select';
import { TextFieldDefault } from '../../components/textfield';
import { formatCnpjCpf } from '../../utils/string';
import { ContainerPages } from '../../components/container';

export default function Clientes() {
    const [cliente, setCliente] = React.useState(null);
    const { loading, telefones, getTelefones } = useTelefone();

    function handleTelefone(telefone, t) {
        return telefone.telefone ?
            <Grid key={t} item xs={12} md={3} >
                <TextFieldDefault value={telefone.telefone} label={`Telefone ${t + 1}:`} />
            </Grid>
            :
            ''
    }

    const handleChange = (event, value) => {
        if (value === null) {
            setCliente(null);
        } else {
            setCliente(value);
            getTelefones(value.codigo)
        }
    }

    return (
        <ContainerPages activeAuxBar={true} title='Cadastro de Clientes'>
            <Box p={1}>
                <SelectClientes id='cliente' onChange={handleChange} value={cliente} label='Cliente' />
            </Box>
            <Divider />
            <Box p={1}>
                <Grid container direction="row"
                    spacing={1}
                >
                    {cliente ? 
                        <>
                            <Grid item xs={4} md={2}>
                                <TextFieldDefault id='end' value={cliente.codigo} label='Código' />
                            </Grid>
                            <Grid item xs={8} md={2}>
                                <TextFieldDefault id='end' value={formatCnpjCpf(cliente.cnpjcpf)} label='CNPJ' />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextFieldDefault id='end' value={cliente.nome} label='Nome' />
                            </Grid>
                            <Grid item xs={10}>
                                <TextFieldDefault id='end' value={cliente.endereco} label='Endereço' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextFieldDefault id='end' value={cliente.numero} label='Nº' />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextFieldDefault id='end' value={cliente.bairro} label='Bairro' />
                            </Grid>
                            <Grid item xs={10} md={5}>
                                <TextFieldDefault id='end' value={cliente.municipio} label='Município' />
                            </Grid>
                            <Grid item xs={2}>
                                <TextFieldDefault id='end' value={cliente.uf} label='UF' />
                            </Grid>
                            {loading ? '' :
                                telefones.TELEFONES.map(handleTelefone)
                            }
                        </>
                    : '' }
                </Grid>
            </Box>
        </ContainerPages>
    );
}
