import { useState } from "react";
import axios from "../services/api";
import useToken from "./useToken";

const useClientesExterno = () => {
  const { renovaToken } = useToken();
  const token = 'Bearer ' + sessionStorage.getItem('token_sac');
  const [loading, setLoading] = useState(true);
  const [cliente, setCliente] = useState([]);

  const getCliente = async(cnpjcpf) => {
    var config = {
      method: 'get',
      url: `/clientes?cnpjcpf=${cnpjcpf}`,
      headers: {
        'Authorization': token
      }
    }
    axios(config)
      .then(function (response) {
        setCliente(response.data.result);
        setLoading(false);
      })
      .catch(function (error) {
        renovaToken();
      });
  }

  return { loading, cliente, getCliente };
}

export default useClientesExterno;



