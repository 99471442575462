import * as React from 'react';
import useSac from '../../hooks/useSac';
import { Grid, FormGroup, FormControlLabel, Box, Divider, Paper, LinearProgress, ButtonGroup, Checkbox } from '@mui/material';
import { AccordionAtendimentos } from '../../components/accordion/index';
import { SelectAssuntos, SelectClientes, SelectPesquisa, SelectSetores, SelectTipoData, SelectUsuarios } from '../../components/select';
import { TextFieldDefault } from '../../components/textfield';
import { formatInputDate } from '../../utils/date';
import { ContainerPages } from '../../components/container';
import { ButtonToolTip } from '../../components/button';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { SearchIcon } from '../../components/icons';
import { Title } from '../../components/typography';
import { listaLength } from '../../utils/string';
import { getLayout, getThemeContraste, heightScreen } from '../../utils/theme';
import { colorDetail, colorSac } from '../../utils/sac';
import { HeaderSac } from '../../components/sac/header';
import LayoutSac from '../../components/layout';
import SacResumo from '../../components/sac/resumo';
import { orderList } from '../../utils/geral';


export default function SacFinalizados() {
    const { getFinalizados, loadingSac, listFinalizados } = useSac();
    const [expanded, setExpanded] = React.useState(false);
    const [params, setParams] = React.useState({ setor: '', assunto: '', filtro: "Conteudo", pesquisa: '', atendente: null, pessoa: null, grupo: false, tpData: 'F', datai: formatInputDate(Date.now()), dataf: formatInputDate(Date.now()) });
    const [sac, setSac] = React.useState({});
    const [codigo, setCodigo] = React.useState(0);

    function setDestaque(atendimento) {
        setSac(atendimento);
        setCodigo(atendimento.codigo);
    }

    function ListaAtendimentos(atendimento) {
        return (getLayout() === 'padrao' ?
            <AccordionAtendimentos key={atendimento.codigo} codigo={codigo}
                color={true} atendimento={atendimento} />
            :
            <Paper key={atendimento.codigo} elevation={atendimento.codigo === codigo ? 10 : 0} style={{ background: 'inherit' }}>
                <Box style={colorSac(atendimento)} sx={colorDetail(atendimento, codigo)} p={1} mb={1} onClick={() => setDestaque(atendimento)}>
                    <HeaderSac codigo={atendimento.codigo} atendimento={atendimento} />
                </Box >
            </Paper>
        )
    }

    const handleChange = (event) => {
        let name = event.target.name;
        let value = (name === 'grupo' ? event.target.checked : event.target.value);
        setParams((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        getFinalizados(params)
    };

    return (
        <ContainerPages title={`Finalizados - (${listaLength(listFinalizados)})`} activeAuxBar={true}>
            <p />
            <form onSubmit={handleSubmit} >
                {expanded ?
                    <Box>
                        <Grid container spacing={0}>
                            <Grid item xs={12} lg={4}>
                                <SelectTipoData label='Tipo de Data' id='tpData' name='tpData' onChange={handleChange} value={params.tpData} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldDefault id='datai' name='datai' type='date' onChange={handleChange} value={formatInputDate(params.datai)} label='Data Inicial' />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextFieldDefault id='dataf' name='dataf' type='date' onChange={handleChange} value={formatInputDate(params.dataf)} label='Data Final' />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectSetores label='Setor' id='setor' name='setor' onChange={handleChange} value={params.tipo} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectAssuntos label='Assunto' id='assunto' name='assunto' onChange={handleChange} value={params.assunto} />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <SelectUsuarios id='atendente' onChange={(event, value) => setParams((prevalue) => {
                                    return {
                                        ...prevalue,
                                        'atendente': value
                                    }
                                })} value={params.atendente} label='Atendente' />
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <SelectClientes id='pessoa' onChange={(event, value) => setParams((prevalue) => {
                                    return {
                                        ...prevalue,
                                        'pessoa': value
                                    }
                                })} value={params.pessoa} label='Cliente' />
                            </Grid>
                            <Grid item xs={12} lg={1} pt={3} style={{ textAlign: 'center' }}>
                                <FormGroup >
                                    <FormControlLabel control={<Checkbox name='grupo' onChange={handleChange} value={params.grupo} />} label="Grupo" style={{ color: getThemeContraste() }} />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={12} md={2}>
                                    <SelectPesquisa value={params.filtro} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <TextFieldDefault id='pesquisa' name='pesquisa' onChange={handleChange}
                                        value={params.pesquisa}
                                        label='Pesquisar'
                                        typeButton='submit' />
                                </Grid>
                                <Grid item xs={12} md={1} style={{ textAlign: 'center' }}>
                                    <ButtonGroup variant="contained" aria-label="Basic button group">
                                        <ButtonToolTip title='Ocultar Avançado' icon={<ZoomOutIcon color='white' />} onClick={() => setExpanded(!expanded)} />
                                        <ButtonToolTip title='Pesquisar' icon={<SearchIcon color='white' />} onClick={handleSubmit} />
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box>
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center" >
                            <Grid item xs={12} lg={2}>
                                <SelectTipoData label='Tipo de Data' id='tpData' name='tpData' onChange={handleChange} value={params.tpData} />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <TextFieldDefault id='datai' name='datai' type='date' onChange={handleChange} value={formatInputDate(params.datai)} label='Data Inicial' />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <TextFieldDefault id='dataf' name='dataf' type='date' onChange={handleChange} value={formatInputDate(params.dataf)} label='Data Final' />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SelectClientes id='pessoa' onChange={(event, value) => setParams((prevalue) => {
                                    return {
                                        ...prevalue,
                                        'pessoa': value
                                    }
                                })} value={params.pessoa} label='Cliente' />
                            </Grid>
                            <Grid item xs={12} md={1} >
                                <FormGroup style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <FormControlLabel control={<Checkbox name='grupo' onChange={handleChange} value={params.grupo} />} label="Grupo" style={{ color: getThemeContraste() }} />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={1} style={{ textAlign: 'center', marginTop: '15px' }}>
                                <ButtonGroup variant="contained" aria-label="Basic button group" >
                                    <ButtonToolTip title='Filtro Avançado' icon={<ZoomInIcon color={getThemeContraste()} />} onClick={() => setExpanded(!expanded)} />
                                    <ButtonToolTip title='Pesquisar' icon={<SearchIcon color={getThemeContraste()} />} onClick={handleSubmit} />
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                }
            </form>
            <p />
            {loadingSac ? <LinearProgress /> :
                listaLength(listFinalizados) > 0 ?
                    <Box style={{ cursor: 'pointer' }}>
                        <Divider textAlign='left' style={{ marginLeft: '-130px', marginTop: '5px' }}>
                            <Title title='Resultado da Pesquisa' />
                        </Divider>
                        <p />
                        <LayoutSac
                            padrao={listaLength(listFinalizados) > 0 ? orderList(listFinalizados, 'data_finalizacao').map(ListaAtendimentos) : ''}
                            colL={listaLength(listFinalizados) > 0 ? orderList(listFinalizados, 'data_finalizacao').map(ListaAtendimentos) : ''}
                            colR={sac.codigo ?
                                <SacResumo atendimento={sac} show={true} />
                                :
                                <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                            }
                        />
                    </Box> : <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
            }
        </ContainerPages >
    );
}



