import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";
import { getSession, setSession } from "../utils/storage";

const useAnexosExternos = () => {
  const { token } = useToken();
  const [anexos, setAnexos] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [statusBar, setStatusBar] = useState(false);
  const { toggleMensagem } = useContext(GeralContext);

  let config = {
    headers: {
      'Authorization': token
    },
  };

  const getAnexos = async (codigo) => {
    try {
      const response = await axios.get(`/atendimentos/${codigo}/anexos/externos`, config)
      setAnexos(response.data.result);
    } catch (error) {
    }
  }

  const postAnexos = async (codigo, arq) => {
    setStatusBar(true);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', arq);
    axios.post(`/atendimentos/${codigo}/anexos/externos`, formData, {
      headers: {
        'Authorization': token
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    })
      .then(response => {
        setStatusBar(false);
      })
      .catch(error => {
        toggleMensagem(error.response.data.error, 'error')
      });
  }

  const deleteAnexos = async (codigo, nome) => {
    try {
      setStatusBar(true);
      const urlEnconded = nome;
      await axios.delete(`/atendimentos/${codigo}/anexos/externos/${urlEnconded}`, config);
      setProgress(0)
      setStatusBar(false);

    } catch (error) {
      setProgress(0)
      setStatusBar(false);

    }
  }

  return { getAnexos, postAnexos, deleteAnexos, anexos, progress, setProgress, statusBar }
}
export default useAnexosExternos;