import { useEffect, useState } from "react";
import axios from "../services/api";
import useToken from "./useToken";

const useClientes = () => {
  const { renovaToken } = useToken();
  const token = 'Bearer ' + sessionStorage.getItem('token_sac');
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);


  useEffect(() => {
    var config = {
      method: 'get',
      url: '/clientes',
      headers: {
        'Authorization': token
      }
    }
    axios(config)
      .then(function (response) {
        setClientes(response.data.result);
        setLoading(false);
      })
      .catch(function (error) {
        renovaToken();
      });
    // eslint-disable-next-line
  }, [])


  return { loading, clientes };
}

export default useClientes;



