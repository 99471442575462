import * as React from 'react';
import { ContainerPages } from '../../components/container';
import { SelectAssuntos, SelectClientes, SelectPrioridade, SelectSetores, SelectSoftware } from '../../components/select';
import { TextFieldButton, TextFieldButton2, TextFieldDefault } from '../../components/textfield';
import { iniAtendimento } from '../../utils/constructor';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ButtonConcluido } from '../../components/button';
import { StackRight } from '../../components/stack';
import { formatInputDateTime } from '../../utils/date';
import useSac from '../../hooks/useSac';
import { useParams } from 'react-router-dom';
import { getThemeContrasteColor } from '../../utils/theme';
import useConteudos from '../../hooks/useConteudos';
import { EditIcon } from '../../components/icons';
import { getSession } from '../../utils/storage';
import { getSetor } from '../../utils/sac';


export default function FormSac() {
    const [atendimento, setAtendimento] = React.useState(iniAtendimento);
    const [editConteudo, setEditConteudo] = React.useState(false);
    const [codConteudo, setCodConteudo] = React.useState(-1);
    const { putConteudo } = useConteudos();
    const [textConteudo, setTextConteudo] = React.useState('');
    const { codigo } = useParams();
    const { getSac, sac, loading, putSac, postSac } = useSac();
    const [bloq, setBloq] = React.useState(false);

    React.useEffect(() => {
        if (window.location.pathname !== '/sac/create') {
            getSac(codigo);
            setAtendimento({
                tipo: sac.tipo,
                pessoa: { codigo: sac.codpessoa, nome: sac.pessoa, cnpjcpf: sac.cnpjcpf },
                assunto: sac.codassunto,
                prioridade: sac.prioridade,
                codigo: sac.codigo,
                contato: sac.contato,
                conteudos: sac.conteudos,
                datahora: formatInputDateTime(Date.now()),
                software: sac.software,
                caminhobd: sac.caminhobd,
            });
        } else {
            setAtendimento((prevalue) => {
                return {
                    ...prevalue,
                    tipo: getSetor()
                }
            })
        }
    }, [loading]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setAtendimento((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })

        if (name === 'assunto') {
            const p = JSON.parse(getSession('assuntos'));
            for (let i = 0; i < p.length; i++) {
                if (p[i].codigo === value) {
                    setAtendimento((prevalue) => {
                        return {
                            ...prevalue,
                            prioridade: p[i].prioridade
                        }
                    })
                }
            }
        }
    }

    const salvarConteudo = () => {
        putConteudo(
            {
                'codigo': atendimento.codigo,
                'idConteudo': codConteudo,
                'conteudo': textConteudo,
            });
        setCodConteudo(-1);
        setTextConteudo()
        setEditConteudo(false);
        getSac(codigo);
    }

    const handleChangeConteudo = (event) => {
        setTextConteudo(event.target.value)
    }

    function showComponents(conteudo) {
        setCodConteudo(conteudo.codigo);
        setTextConteudo(conteudo.descricao)
        setEditConteudo(true);

    }

    function ListConteudos(conteudo) {
        return (<div key={conteudo.codigo}>
            {editConteudo && codConteudo === conteudo.codigo ?
                <TextFieldButton2 label={conteudo.titulo} value={textConteudo} onChange={handleChangeConteudo} onClickCancel={editConteudo} onClickCheck={salvarConteudo} />
                :
                conteudo.descricao === '' ? '' : <TextFieldButton key={conteudo.codigo} disabled={true} onClick={() => showComponents(conteudo)} value={conteudo.descricao} name='conteudo' label={conteudo.titulo} icon={<EditIcon fontSize='14px' />} />
            }
        </div >
        )
    }

    const enviarSac = () => {
        setBloq(true);
        if (window.location.pathname === '/sac/create')
            postSac(atendimento)
        else {
            setAtendimento((prevalue) => {
                return {
                    ...prevalue,
                    conteudo: sac.conteudos
                }
            })
            putSac('', atendimento)
        }
    }

    return (
        <ContainerPages activeAuxBar={true} title={sac.codigo ? 'Editar atendimento' : 'Novo atendimento'}>
            {
                loading && window.location.pathname !== '/sac/create' ?
                    <Box style={{ textAlign: 'center' }} pt={5}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box pt={2}>
                        <SelectSetores onChange={handleChange} value={atendimento.tipo} />
                        <SelectClientes onChange={(event, value) => setAtendimento((prevalue) => {
                            return {
                                ...prevalue,
                                'pessoa': value
                            }
                        })} value={atendimento.pessoa} />
                        <SelectAssuntos onChange={handleChange} value={atendimento.assunto} />
                        <SelectPrioridade onChange={handleChange} value={atendimento.prioridade} />
                        <SelectSoftware onChange={handleChange} value={atendimento.software} />
                        <TextFieldDefault onChange={handleChange} value={atendimento.contato} name='contato' label='Contato' />
                        <TextFieldDefault onChange={handleChange} value={atendimento.caminhobd} name='caminhobd' label='Caminho do Banco' />
                        {window.location.pathname !== '/sac/create' ?
                            <Box pt={1}>
                                <Typography variant='subtitle2' color={getThemeContrasteColor()} pl={1}>Conteúdos</Typography>
                                {sac.conteudos.map(ListConteudos)}
                            </Box>
                            :
                            <TextFieldDefault onChange={handleChange} value={atendimento.conteudo} name='conteudo' label='Conteúdo' multiline={true} rows={5} />
                        }
                        <StackRight>
                            <ButtonConcluido disabled={bloq} onClick={enviarSac} />
                        </StackRight>
                    </Box>
            }
        </ContainerPages >
    )
}
