import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuIcon from '@mui/icons-material/Menu';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import Search from '@mui/icons-material/Search';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import { CheckOutlined, Close } from '@mui/icons-material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import TopicIcon from '@mui/icons-material/Topic';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import MessageIcon from '@mui/icons-material/Message';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplayIcon from '@mui/icons-material/Replay';
import BiotechIcon from '@mui/icons-material/Biotech';

export function Power(props) {
    return <PowerSettingsNewIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function IconMenu(props) {
    return <MenuIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function TreeBox(props) {
    return <AccountTreeOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function GpsFixed(props) {
    return <GpsFixedOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function BookMark(props) {
    return <BookmarksOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function People(props) {
    return <PermIdentityOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Done(props) {
    return <DoneOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function ListIcon(props) {
    return <ListOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Book(props) {
    return <MenuBookIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Settings(props) {
    return <SettingsOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Add(props) {
    return <AddCircleIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function ArrowLeft(props) {
    return <ChevronLeftIcon color={props.color} style={{ fontSize: props.fontSize }} />
};

export function ArrowKeyLeft(props) {
    return <ArrowBackIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function SearchIcon(props) {
    return <Search color={props.color} style={{ fontSize: props.fontSize }} />
};
export function SearchOff(props) {
    return <SearchOffOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function CloseIcon(props) {
    return <Close color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Check(props) {
    return <CheckOutlined color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Star(props) {
    return <StarOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Arrow(props) {
    return <KeyboardArrowRightIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function File(props) {
    return <AttachFileIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Time(props) {
    return <AccessTimeOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Alarm(props) {
    return <AccessAlarmsOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function NearMe(props) {
    return <NearMeOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function LastPage(props) {
    return <LastPageOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function HighLigth(props) {
    return <HighlightOffOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Delete(props) {
    return <DeleteIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Send(props) {
    return <SendOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function EditIcon(props) {
    return <BorderColorIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function BackupRestore(props) {
    return <SettingsBackupRestoreOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function ArrowCircle(props) {
    return <ArrowCircleRightOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Phone(props) {
    return <LocalPhoneOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Person(props) {
    return <PersonOutlineOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function More(props) {
    return <MoreHorizOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function MoreV(props) {
    return <MoreVertIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Thumb(props) {
    return <ThumbUpOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function Create(props) {
    return <CreateOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function VisibilityOn(props) {
    return <VisibilityOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function VisibilityOff(props) {
    return <VisibilityOffOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function PostIcon(props) {
    return <PostAddOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function FormatList(props) {
    return <FormatListNumberedOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function LinkOn(props) {
    return <LinkOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function LinkOff(props) {
    return <LinkOffOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function PersonalRemove(props) {
    return <PersonRemoveOutlinedIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function ListFolder(props) {
    return <TopicIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function SunIcon(props) {
    return <WbSunnyIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function MoonIcon(props) {
    return <NightlightRoundIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function ModeMail(props) {
    return <VerticalSplitIcon color={props.color} style={{ fontSize: props.fontSize }} />
};
export function AZIcon(props) {
    return <SortByAlphaIcon color={props.color} style={{ fontSize: props.fontSize }} />
};

export function IconMessage(props) {
    return <MessageIcon color={props.color} style={{ fontSize: props.fontSize }} />
};

export function IconMark(props) {
    return <BookmarkIcon color={props.color} style={{ fontSize: props.fontSize }} />    
};

export function Back(props) {
    return <ReplayIcon style={{ fontSize: props.fontSize, color: props.color }} />
};

export function Lab(props) {
    return <BiotechIcon style={{ fontSize: props.fontSize, color: props.color }} />
};