import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useRastreios from '../../hooks/useRastreios';
import { Box, Stack, Typography } from '@mui/material';
import { TextFieldButton2 } from '../../components/textfield';
import { ContainerPages } from '../../components/container';
import { ButtonToolTip } from '../../components/button';
import { Add, Delete, EditIcon } from '../../components/icons';
import { getThemeContraste } from '../../utils/theme';
import { TreeItem, TreeView } from '@mui/x-tree-view';

export default function Rastreios() {
    const { getRastreios, postRastreio, removeRastreio, rastreios, loading, putRastreio } = useRastreios();
    const [master, setMaster] = React.useState({ codigo: 0, descricao: 'Rastreios', master: 0 });
    const [item, setItem] = React.useState({ codigo: 0, descricao: '', master: 0 });
    const [at, setAt] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            getRastreios();
            setAt(false);
            let descricaoInput = document.querySelector('input[name="descricao"]');
            if (descricaoInput)
                descricaoInput.focus();
        }, 1000);
    }, [at === true])

    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={item.descricao} onClick={() => setMaster(item)}>
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function addItem() {
        if (item.codigo === 0) {
            postRastreio({ master: master.codigo, descricao: item.descricao });
        } else {
            putRastreio(item);
        }
        setItem({ codigo: 0, descricao: '', master: 0 });
        setAt(true);
    }

    function removeItem() {
        removeRastreio(master.codigo)
        setAt(true);
    }

    function cancelarItem() {
        setItem('')
        setAt(true);
    }

    const handleChange = (event) => {
        let value = event.target.value;
        setItem((prevalue) => {
            return {
                ...prevalue,
                'descricao': value
            }
        })
    }
    function editarItem() {
        setItem(master);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addItem();
    }


    return (
        <ContainerPages activeAuxBar={true} title='Rastreios'
            btnAux={<Stack direction='row' spacing={1} justifyContent='flex-end'>
                <ButtonToolTip title='Novo item' color='green' icon={<Add />} onClick={() => setItem({ codigo: 0, descricao: '', master: 0 })} />
                <ButtonToolTip title='Editar item' color='blue' icon={<EditIcon />} onClick={() => editarItem()} />
                <ButtonToolTip title='Remover Item' color='red' icon={<Delete />} onClick={() => removeItem()} />
            </Stack>}
        >
            <Box p={1}>
                <form onSubmit={handleSubmit}>
                    <Typography fontSize={12} style={{ color: getThemeContraste(), letterSpacing: '1px', marginBottom: '-20px' }}>
                        Item Rastreio
                    </Typography>
                    <TextFieldButton2 name='descricao' onChange={handleChange} onClickCancel={() => cancelarItem()} value={item.descricao} />
                </form>
                <div style={{ height: '400px', overflow: 'auto', marginTop: '10px' }}>
                    <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={['0']}
                    >
                        <TreeItem nodeId={'0'} label='Rastreios' onClick={() => setMaster({ codigo: 0, descricao: 'Rastreios', master: 0 })} style={{ color: getThemeContraste() }}>
                            {loading ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                        </TreeItem>
                    </TreeView>
                </div>
            </Box>
        </ContainerPages >
    );
}