import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { SelectPesquisa } from '../select';
import { TextFieldButton2 } from '../textfield';
import { GeralContext, SacContext } from '../../context';

export default function SearchBar(props) {
    const { pesquisar, ativarPesquisa, togglePesquisar, clearPesquisar, clickPesquisar } = React.useContext(GeralContext);
    const { toggleCodigo } = React.useContext(SacContext);

    if (ativarPesquisa || props.pesqFinalizar === true) {
        return (
            <Box pl={2} pr={2}>
                < form onSubmit={clickPesquisar} >
                    <Grid container>
                        <Grid item xs={12} md={2}>
                            <SelectPesquisa value={pesquisar.filtro} onChange={togglePesquisar} />
                        </Grid>
                        <Grid item xs={12} md={10} pt={1}>
                            <TextFieldButton2
                                id='valor'
                                value={pesquisar.valor}
                                onClickCheck={() => toggleCodigo(0)}
                                onClickCancel={clearPesquisar}
                                onChange={togglePesquisar}
                                label='Pesquisar'
                                typeButton='submit' />
                        </Grid>
                    </Grid>
                </form >
            </Box>
        );
    } else {
        return '';
    }
}