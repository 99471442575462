import * as React from 'react';
import { Box, ButtonGroup, Divider } from '@mui/material';
import { StackJustify } from '../stack';
import { getThemeContraste } from '../../utils/theme';
import { Link } from 'react-router-dom';
import { GeralContext } from '../../context';
import { ButtonToolTip } from '../button';
import { Back } from '../icons';

export default function AuxBar(props) {
    const { toggleAtualizar } = React.useContext(GeralContext);

    return (
        <Box pr={1} pl={1}>
            <StackJustify>
                <p style={{
                    boxSizing: 'border-box',
                    borderBottom: `2px solid #ed3237`,
                    margin: '0px',
                    fontWeight: '500',
                    fontSize: '0.975rem',
                    letterSpacing: '0.02857em',
                    maxWidth: '200px',
                    padding: '12px 16px 12px',
                    color: getThemeContraste()
                }}>
                    {props.title}
                </p>

                <ButtonGroup
                    color="primary"
                    size="large"
                    variant="text"
                >
                    {props.btnAux}
                    <Link to='/sac' onClick={() => toggleAtualizar(true)}><ButtonToolTip title='Voltar' icon={<Back color='orange' />} /></Link>
                </ButtonGroup>
            </StackJustify>
            <Divider />
        </Box >
    );
}


