import React, { useContext } from 'react';
import { NavBar, NavBarSimple } from '../appbar';
import Container from '@mui/material/Container';

import { getBackground, heightDefault } from '../../utils/theme';
import SearchBar from '../search';
import { Box } from '@mui/material';
import { GeralContext } from '../../context';
import AuxBar from '../auxbar';
import SnackbarAlert from '../snackbar';

export function ContainerPages(props) {
    document.body.style.background = getBackground();

    const { ativarPesquisa, mensagem } = useContext(GeralContext);
    return (
        <Container maxWidth='xxl' style={{ padding: '0px' }}>
            {props.navBarSimple ? <NavBarSimple /> : <NavBar />}
            <SearchBar />
            <Box pl={1} pr={1} height={heightDefault()} style={{ background: 'inherit' }}>
                {props.activeAuxBar ? <AuxBar title={props.title} btnAux={props.btnAux} /> : ''}
                {window.location.pathname === '/sac' || window.location.pathname === '/testcomplete' ?
                    props.children
                    :
                    <Box sx={{
                        height: heightDefault(ativarPesquisa) - 50,
                        overflow: 'auto',
                    }} >
                        {props.children}
                    </Box>
                }
            </Box>
            <SnackbarAlert msg={mensagem} />
        </Container>
    );
}