import { Divider, Stack, Switch } from '@mui/material';
import * as React from 'react';
import { ButtonFechar } from '../../../../button';
import SacResumo from '../../../resumo';
import useSac from '../../../../../hooks/useSac';
import { getUser } from '../../../../../utils/user';
import { TextConteudo, TitleGrupos, TitleSmall } from '../../../../typography';
import { StackLeft } from '../../../../stack';

export default function DialogUltimos(props) {
    const { getUltimos, listUltimos } = useSac();
    const codpessoa = props.codpessoa;

    React.useEffect(() => {
        getUltimos({ codpessoa: codpessoa, usuario: false });
    }, [])


    const handleChange = (event) => {
        getUltimos({ codpessoa: codpessoa, usuario: event.target.checked });
    };


    function ListaAtendimentos(atendimento, a) {
        if (atendimento.status === 'A')
            return <span key={a}></span>;
        else
            return <SacResumo atendimento={atendimento} color={true} atender={true} />
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Ultimos Atendimentos' /></Divider>
            <Stack spacing={1} paddingRight={1} paddingBottom={1}>
                <StackLeft><Switch size='small' value={getUser().sub} onChange={handleChange} /><TitleSmall title='Somente meus Atendimentos' /></StackLeft><p />
                {listUltimos.length > 0 ? listUltimos.map(ListaAtendimentos) : <TextConteudo text='Nenhum atendimento encontrado' />}
                <Stack direction='row' spacing={1} justifyContent='flex-end' >
                    <ButtonFechar />
                </Stack>
            </Stack>
        </>
    );
}
