import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";
import { setSession } from "../utils/storage";

const useMovimento = () => {
    const { token } = useToken();
    const [loading, setLoading] = useState(true)
    const [listMovimento, setListMovimento] = useState([]);
    const { toggleMensagem } = useContext(GeralContext);

    const config = {
        headers: {
            authorization: token
        }
    };

    const deleteMovimento = async (id) => {
        try {
            const response = await axios.get(`/atendimentos/movimentos/${id}`, config);
            toggleMensagem(response.data.result, 'error');
        } catch (error) {
            toggleMensagem('Erro ao excluir movimento.', 'error');
        }
    }

    const postMovimento = async (parameters) => {
        try {
            const response = await axios.post('/atendimentos/movimentos', parameters, config);
            toggleMensagem(response.data.result, 'success');
        } catch {
            toggleMensagem('Não foi possível cadastrar o movimento', 'error');
        }
    }

    const putMovimento = async (parameters) => {
        try {
            const response = await axios.put(`/atendimentos/movimentos/${parameters.codigo}`, parameters, config);
            toggleMensagem(response.data.result, 'success');
        } catch {
            toggleMensagem('Não foi possível alterar o movimento', 'error');
        }
    }

    const getMovimentos = async (id) => {
        setLoading(true);
        const url = id ? `/atendimentos/movimentos/${id}` : '/atendimentos/movimentos';
        const response = await axios.get(url, config);
        setListMovimento(response.data.result);
        setSession('movimentos', JSON.stringify(response.data.result));
        setLoading(false)
    }

    const putMovimentoAt = async (codigo_sac, codigo_mov) => {
        try {
            await axios.put(`/atendimentos/${codigo_sac}/movimentar`, { movimento: parseInt(codigo_mov) }, config);
        } catch { }
    }


    return { loading, listMovimento, getMovimentos, deleteMovimento, postMovimento, putMovimento, putMovimentoAt };
}

export default useMovimento;